document.addEventListener("DOMContentLoaded", function() {
  // Fix for https://www.notion.so/buzzword/Display-issue-Left-column-overlaps-with-reviews-1fbb7645d0db4fd09572da4954a83cf8?pvs=4
  // Finds the height of the interior rail and interior content and adds a class to the testimonial wrapper if the rail is taller than the content
  // This class will add padding to the testimonial wrapper to prevent the left column from overlapping with the reviews

  // Get elements with corresponding classes
  var interiorRailWrapper = document.querySelector('.interior-rail-wrapper');
  var interiorContentWrapper = document.querySelector('.interior-content-wrapper');
  var testimonialWrapper = document.querySelector('.section-home-widget .testimonial-wrapper');

  if (interiorRailWrapper && interiorContentWrapper && testimonialWrapper) {
    var railHeight = interiorRailWrapper.clientHeight;
    var contentHeight = interiorContentWrapper.clientHeight;

    // Compare heights and add class if rail is taller than content
    if (railHeight > contentHeight) {
      testimonialWrapper.classList.add('small-wrapper');
    }
  }
});
